<template>
    <header id="header" class="align-items-center text-center">
        <h1 id="logo">聯絡人</h1>

        <form id="searchForm" class="d-flex justify-content-center"
              @submit.prevent="state.search = $refs.search.value;">
            <input type="text" name="search" class="form-control w-75" placeholder="搜尋" ref="search">
        </form>
    </header>

    <aside id="aside">
        <button id="addContact" class="btn btn-secondary w-75 ml-2" @click="open('建立新聯絡人',addContact)">新增聯絡人</button>
        <hr>
        <ul class="list list-unstyled">
            <li class="item" @click="state.current = ''" :class="{current: state.current.length === 0}">聯絡人<span
                class="num float-right">{{ state.contact.length }}</span>
            </li>
            <hr>
            <ul id="tagsList" class="list list-unstyled">
                <template v-for="(tag,i) in state.tags" :key="i">
                    <li class="item" @click="state.current = tag.name" :class="{current: state.current === tag.name}">
                        {{ tag.name }}<span class="num float-right">{{ tag.count }}</span></li>
                </template>

                <li id="addTag" class="item btn btn-link" @click="open('新增標籤',addTag)">建立標籤</li>
            </ul>
            <hr>
            <li class="item" @click="state.current = 'trash'" :class="{current: state.current === 'trash'}">垃圾桶</li>
        </ul>
    </aside>

    <main id="main">
        <div class="message text-center" v-if="typeof state.contacts === 'string'">{{ state.contacts }}</div>

        <ul class="contacts list-unstyled"
            @scroll="virtualScroll($event.target.scrollTop)"
            v-else>
            <li class="contact" v-for="(contact,i) in state.contacts" :key="i"
                @mouseover="state.showIndex = i"
                @mouseleave="state.showIndex =  -1"
                :style="'transform:translateY(' + contact.y + 'px)'"
            >
                <img :src="contact.img" alt="" class="avatar">
                <span class="fullname">{{ contact.last_name + contact.first_name }}</span>
                <span class="email">{{ contact.email[0] ?? '' }}</span>
                <span class="phone">{{ contact.phone[0] ?? '' }}</span>
                <ul class="tags d-flex">
                    <li class="tag badge badge-info ml-2" v-for="(e,i) in contact.tags" :key="i">{{ e }}</li>
                </ul>

                <div class="actions" v-if="state.showIndex === i">
                    <button class="edit btn btn-warning mr-1" @click="open('編輯新聯絡人',updateContact,contact)">編輯</button>
                    <button class="delete btn btn-danger" @click.prevent="destroyContact(contact)">刪除</button>
                </div>
            </li>

            <li :style="'height:' +(state.contacts.length - virtual.count) * virtual.height + 'px'"></li>
        </ul>
    </main>

    <dialog id="dialog" :ref="dialog.visible">
        <h2 class="title">{{ dialog.state.title }}</h2>

        <form :class="dialog.state.isTag ? 'newTag':'newContact'" @submit.prevent="dialog.state.event">
            <template v-if="dialog.state.isTag">
                <input type="text" name="name" v-model="dialog.state.data.name" class="form-control">
            </template>

            <template v-else>
                <input type="file" class="avatar d-none" ref="previewRef" @change="preview"
                       accept=".bmp,.jpg,.jpeg,.png,.gif">
                <img :src="dialog.state.data.img" class="avatar_preview" @click.prevent="$refs.previewRef.click">
                <hr>
                <input type="text" name="last_name" class="form-control mt-2" v-model="dialog.state.data.last_name"
                       placeholder="姓氏" required>
                <input type="text" name="first_name" class="form-control mt-2" v-model="dialog.state.data.first_name"
                       placeholder="名字" required>
                <template v-for="(e,i) in dialog.state.data.email" :key="i">
                    <input type="email" name="email[]" class="form-control mt-2" v-model="dialog.state.data.email[i]"
                           @dblclick="dialog.state.data.email.splice(i,1)">
                </template>
                <input type="email" name="email[]" ref="email" class="form-control mt-2"
                       @keydown.enter.prevent="dialog.state.data.email.push($refs.email.value);$refs.email.value=''"
                       placeholder="電子郵件">

                <template v-for="(e,i) in dialog.state.data.phone" :key="i">
                    <input type="tel" name="phone[]" class="form-control mt-2" v-model="dialog.state.data.phone[i]"
                           @dblclick="dialog.state.data.phone.splice(i,1)">
                </template>
                <input type="tel" name="phone[]" ref="phone" class="form-control mt-2"
                       @keydown.enter.prevent="dialog.state.data.phone.push($refs.phone.value);$refs.phone.value=''"
                       placeholder="電話">

                <ul class="tags p-0 mb-0 mt-1">
                    <template v-for="(e,i) in state.tags" :key="i">
                        <input type="checkbox" :id="i" name="tags[]" :value="e.name" v-model="dialog.state.data.tags">
                        <label :for="i">{{ e.name }}</label>
                    </template>
                </ul>

                <textarea class="form-control mt-2" v-model="dialog.state.data.note" placeholder="備註"></textarea>
            </template>

            <hr>
            <button class="close btn btn-link float-right" @click.prevent="dialog.visible.value.close()">取消</button>
            <button class="submit btn btn-link float-right">儲存</button>
        </form>
    </dialog>
</template>

<script>
import {onMounted, inject, reactive, ref, computed} from "vue";

export default {
    name: 'App',
    setup() {
        const api = {
            tag: inject('Tag'),
            contact: inject('Contact')
        }

        const edit = inject('Edit');

        let previewRef = ref();
        let state = reactive({
            showIndex: -1,
            search: '',
            current: '',
            tags: [],
            contact: [],
            contacts: computed(() => {
                let contact = state.contact;

                if (state.current.length > 0) {
                    contact = contact.filter(contact => contact.tags.includes(state.current));
                }

                if (state.search.length > 0) {
                    contact = contact.filter(contact =>
                        contact.last_name.indexOf(state.search) !== -1 ||
                        contact.first_name.indexOf(state.search) !== -1 ||
                        (contact.last_name + contact.first_name).indexOf(state.search) !== -1 ||
                        contact.email.indexOf(state.search) !== -1 ||
                        contact.phone.indexOf(state.search) !== -1
                    );

                    if (contact.length === 0) {
                        return '在你的聯絡人中找不到相符的搜尋結果';
                    }
                }

                if (contact.length === 0) {
                    return '目前還沒有任何聯絡人';
                }

                return contact.slice(0, virtual.count - 1);
            })
        });
        let dialog = {
            visible: ref(),
            state: reactive({
                title: '',
                event: null,
                data: {},
                temp: {},
                isTag: computed(() => dialog.state.title.includes('標籤'))
            })
        }
        let virtual = {
            count: 20,
            height: 80
        }

        onMounted(async () => {
            await Promise.all([
                api.tag.init(),
                api.contact.init()
            ]);

            await loading();
        })

        function deep(obj) {
            return JSON.parse(JSON.stringify(obj));
        }

        async function loading() {
            state.tags = await api.tag.get();
            state.contact = await api.contact.get();
        }

        function open(title, event = null, data) {
            dialog.state.title = title;
            dialog.state.event = event;

            if (title.includes('標籤')) {
                dialog.state.data = {
                    name: '',
                    count: 0
                }
            } else if (title.includes('編輯')) {
                dialog.state.temp = deep(data);
                dialog.state.data = deep(data);
            } else {
                dialog.state.data = edit;
            }

            dialog.visible.value.showModal();
        }

        function preview() {
            let file = URL.createObjectURL(previewRef.value.files[0]);
            let image = new Image();

            image.onload = () => {
                let cvs = document.createElement('canvas');
                let ctx = cvs.getContext('2d');

                cvs.width = 120;
                cvs.height = 120;
                ctx.drawImage(image, 0, 0, 120, 120);
                dialog.state.data.img = cvs.toDataURL('image/jpeg');
            }

            image.src = file;
        }

        async function addTag() {
            api.tag.add(deep(dialog.state.data));

            await loading();

            dialog.visible.value.close();
        }

        async function updateTagCount(tags, count = 1) {
            for (let tag of state.tags) {
                if (tags.includes(tag.name)) {
                    tag.count += count;

                    api.tag.update(deep(tag));
                }
            }
        }

        async function addContact() {
            await api.contact.add(deep(dialog.state.data));
            await updateTagCount(dialog.state.data.tags);
            await loading();

            dialog.visible.value.close();
        }

        async function updateContact() {
            for (let tag of state.tags) {
                let originalExist = dialog.state.temp.tags.includes(tag.name);
                let editExist = dialog.state.data.tags.includes(tag.name);

                if (originalExist && !editExist) {
                    tag.count--;
                } else if (!originalExist && editExist) {
                    tag.count++;
                }

                await api.tag.update(deep(tag));
            }

            await api.contact.update(deep(dialog.state.data));
            await loading();
            dialog.visible.value.close();
        }

        async function destroyContact(contact) {
            await updateTagCount(contact.tags, -1);
            await api.contact.delete(contact.id);
            await loading();

            dialog.visible.value.close();
        }

        function virtualScroll(top) {
            let index = Math.floor(top / virtual.height);

            if (index + virtual.count <= state.contact.length) {
                state.contact.slice(index, index + virtual.count).forEach((contact, i) => {
                    contact.y = top;
                    state.contacts[i] = contact;
                })
            }
        }

        return {
            state,
            dialog,
            previewRef,
            virtual,
            open,
            preview,
            addTag,
            addContact,
            updateContact,
            destroyContact,
            virtualScroll
        }
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-template-rows: 10vh 90vh;
}

dialog {
    border: 1px solid #ddd;
    min-width: 30vw;
    min-height: 20vh;
}

body {
    overflow-y: hidden;
}

#header {
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-column: 1/3;
}

.item {
    padding: 10px;
    border-radius: 0 25px 25px 0;
    transition: all .5s;
}

.num {
    margin-right: 10px;
}

.current {
    background-color: #e8f0fe;
    color: #1697d2;
}

.contacts {
    max-height: 90vh;
    overflow-y: scroll;
}

.contact {
    display: grid;
    grid-template-columns: .5fr .5fr 1fr 1fr 1fr 1fr;
    align-items: center;
    height: 80px;
}

.avatar, .avatar_preview {
    border: 1px solid #ddd;
    border-radius: 50%;
    width: 75px;
    height: 75px;
}
</style>
