import {createApp} from 'vue'
import App from './App.vue'

class API {
    constructor(sheet) {
        this.db = null;
        this.sheet = sheet;
    }

    init() {
        return new Promise(resolve => {
            let req = indexedDB.open('module_e');

            req.onsuccess = e => {
                this.db = e.target.result;

                resolve();
            }

            req.onupgradeneeded = e => {
                this.db = e.target.result;

                this.db.createObjectStore('tags', {keyPath: 'id', autoIncrement: true});
                this.db.createObjectStore('contacts', {keyPath: 'id', autoIncrement: true});
            }
        })
    }

    getStore() {
        return this.db.transaction([this.sheet], 'readwrite').objectStore(this.sheet);
    }

    get() {
        return new Promise(resolve => this.getStore().getAll().onsuccess = e => resolve(e.target.result));
    }

    add(val) {
        return new Promise(resolve => this.getStore().add(val).onsuccess = () => resolve());
    }

    update(val) {
        return new Promise(resolve => this.getStore().put(val).onsuccess = () => resolve());
    }

    delete(id) {
        return new Promise(resolve => this.getStore().delete(id).onsuccess = () => resolve());
    }
}

class Tag extends API {
    constructor(props) {
        super(props);
    }
}

class Contact extends API {
    constructor(props) {
        super(props);
    }
}

const app = createApp(App);

app.provide('Tag', new Tag('tags'));
app.provide('Contact', new Contact('contacts'));
app.provide('Edit', {
    img: '',
    first_name: '',
    last_name: '',
    email: [],
    phone: [],
    tags: [],
    note: ''
});
app.mount('#app')
